import { getHTTPClient } from './request';

let apiPrefix = '/api';
let projectId = '';

export const setApiPrefix = (prefix: string) => apiPrefix = prefix;
export const setProjectId = (id: string) => projectId = id;

export const fetchWorkflow = (appId: string, schemaId: string) => {
  const request = getHTTPClient();
  const query = new URLSearchParams({
    appid: appId,
    schemaid: schemaId,
    projectid: projectId,
  });
  return request({
    path: `${apiPrefix}/workflow?${query.toString()}`,
  });
};

export const updateWorkflow = (data: any) => {
  const request = getHTTPClient();
  const { appId, schemaId } = data;
  const query = new URLSearchParams({
    appid: appId,
    schemaid: schemaId,
    projectid: projectId,
  });
  return request({
    method: 'POST',
    path: `${apiPrefix}/workflow?${query.toString()}`,
    data: JSON.stringify(data || {}),
  });
};

export const fetchSchema = (appId: string, schemaId: string) => {
  const request = getHTTPClient();
  const query = new URLSearchParams({
    appid: appId,
    projectid: projectId,
  });
  return request({
    path: `${apiPrefix}/schema/${schemaId}?${query.toString()}`,
  });
};

export const updateSchemaWorkflowSwitch = (appId: string, schemaId: string, data: any) => {
  const request = getHTTPClient();
  const query = new URLSearchParams({
    appid: appId,
    projectid: projectId,
  });
  return request({
    method: 'POST',
    path: `${apiPrefix}/schema/patch/${schemaId}?${query.toString()}`,
    data: JSON.stringify(data),
  });
};
