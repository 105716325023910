<template>
  <div class="container">
    <svg
      :width="width"
      :height="height"
    >
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="10"
          refY="3.5"
          orient="auto"
        >
          <polygon
            fill="var(--edge-color)"
            points="0 0, 10 3.5, 0 7"
          />
        </marker>
        <marker
          id="abArrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="10"
          refY="3.5"
          orient="auto"
        >
          <polygon
            fill="var(--abnormal-edge-color)"
            points="0 0, 10 3.5, 0 7"
          />
        </marker>
      </defs>
      <rect
        x="0"
        y="0"
        :width="width"
        :height="height"
        class="graph-background"
      />
      <g
        ref="graph"
        class="graph"
      >
        <g class="edge-container">
          <g
            v-for="(edge, idx) in edges"
            :key="idx"
            class="edge"
          >
            <polyline
              v-if="edge.points"
              :points="edge.points.join(' ')"
              class="abnormal-edge"
              marker-end="url(#abArrowhead)"
            />
            <line
              v-else
              :x1="edge.x1"
              :y1="edge.y1"
              :x2="edge.x2"
              :y2="edge.y2"
              stroke-width="1"
              marker-end="url(#arrowhead)"
            />
          </g>
        </g>
        <g class="node-container">
          <g
            v-for="(node, idx) in positionedNodes"
            :key="node.id + idx"
            :class="`node ${node.type}`"
            :transform="`translate(${node.x},${node.y})`"
          >
            <title>{{ node.name }}</title>
            <rect
              :width="nodeWidth"
              :height="nodeHeight"
              rx="4"
              ry="4"
            />
            <foreignObject
              x="-50"
              y="-12"
              width="100"
              height="24"
              :transform="`translate(${nodeWidth/2},${nodeHeight/2 + 2})`"
            >
              <div class="text">{{ node.name || node.id }}</div>
            </foreignObject>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { select } from 'd3-selection';
import { drag } from 'd3-drag';

const NODE_WIDTH = 120;
const NODE_HEIGHT = 60;

export default {
  props: {
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: [String, Number],
    nodes: Array,
  },
  data() {
    return {
      positionedNodes: [],
      edges: [],
    };
  },
  computed: {
    nodeWidth() {
      return NODE_WIDTH;
    },
    nodeHeight() {
      return NODE_HEIGHT;
    },
  },
  watch: {
    nodes: {
      handler(newNodes) {
        this.layout(newNodes);
      },
    },
  },
  mounted() {
    this.layout(this.nodes);
    let x = 0;
    let y = 0;
    select('.graph-background').call(drag().on('drag', (event) => {
      // drag smoothly
      x += event.dx * .5;
      y += event.dy * .5;
      this.dragging(x, y);
    }));
  },
  methods: {
    dragging(x, y) {
      select('.graph').attr('transform', `translate(${x},${y})`);
    },

    layout(newNodes) {
      const x = 60;
      const startX = x + (this.nodeWidth / 2);
      const nodeGap = this.nodeHeight / 2;
      const nodes = [];
      const edges = [];
      let exist = false;
      let dstX = 0;
      let dstY = 0;

      newNodes.forEach((node, i) => {
        const y = this.nodeHeight + (i * 90);

        if (node.abnormal) {
          exist = true;
          dstX = x + this.nodeWidth + nodeGap + (this.nodeWidth / 2);
          dstY = y - 90;
          nodes.push({
            x: x + this.nodeWidth + nodeGap,
            y: y - 90,
            id: node.id,
            name: node.name,
            type: 'abnormal-ending',
          });
        } else {
          nodes.push({
            x,
            y,
            id: node.id,
            name: node.name,
            type: node.isEnding ? 'success-ending' : '',
          });
        }

        if (i > 0 && !node.abnormal) {
          edges.push({
            x1: startX,
            y1: y - nodeGap,
            x2: startX,
            y2: y,
          });
        }
      });

      // edge to abnormal-ending node
      if (exist) {
        for (let i = 0; i < nodes.length - 2; ++i) {
          const node = nodes[i];
          const px1 = node.x + this.nodeWidth;
          const py1 = node.y + (this.nodeHeight / 2);
          const px2 = px1 + nodeGap + (this.nodeWidth / 2);
          edges.push({
            points: [
              `${px1},${py1}`,
              `${px2},${py1}`,
              `${dstX},${dstY}`,
            ],
          });
        }
      }

      this.positionedNodes = nodes;
      this.edges = edges;
    },
  },

};
</script>

<style scoped>
.container {
  position: relative;
  border: 1px dashed #efefef;
  --edge-color: #bdbdbd;
  --abnormal-edge-color: #ffccc7;
}

.graph-background {
  fill: white;
}

.node {
  fill: #91d5ff;
}

.success-ending {
  fill: #b7eb8f;
}

.abnormal-ending {
  fill: #fff2f0;
  stroke: #ffccc7;
  stroke-width: 1px;
  stroke-dasharray: 5 3;
}

.abnormal-edge {
  fill: none;
  stroke: var(--abnormal-edge-color);
  stroke-width: 1px;
  stroke-dasharray: 5 3;
}

.edge {
  stroke: var(--edge-color);
}

.text {
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: .8;
}

</style>
