import Vue from 'vue';
import MultiSourceWorkflow from './components/workflow/MultiSourceWorkflow.vue';
import WorkflowDialog from './components/workflow/WorkflowDialog.vue';
import { setHTTPClient } from './request';
import { setApiPrefix, setProjectId } from './services';


export interface InitOptions {
  request: (options: any) => Promise<any>;
  name?: string;
  apiPrefix?: string;
  projectId?: string;
}

export * from './components/workflow/constants';
export const workflowMultiSources = 'wuji-workflow-multi-sources';
export const workflowDialog = 'wuji-workflow-dialog';

export const init = ({ request, apiPrefix = '/api', projectId = '' }: InitOptions) => {
  setHTTPClient(request);
  setApiPrefix(apiPrefix);
  setProjectId(projectId);
  Vue.component(workflowMultiSources, MultiSourceWorkflow);
  Vue.component(workflowDialog, WorkflowDialog);

  return {
    [workflowMultiSources]: MultiSourceWorkflow,
    [workflowDialog]: WorkflowDialog,
  };
};
