// import { getEnabledFeatures } from '../../global-info';

export const ACCEPT_OPERATION = '同意';
export const REJECT_OPERATION = '拒绝';
export const NORMAL_ENDING_ID = 'normal-ending';
export const NORMAL_ENDING_NAME = '正常结束';
export const ABNORMAL_ENDING_ID = 'abnormal-ending';
export const ABNORMAL_ENDING_NAME = '异常结束';
// export const WORKFLOW_ENABLED = getEnabledFeatures().includes('workflow');
export const EMPTY_WORKFLOW = {
  name: '',
  nodes: [
    {
      id: '',
      name: '',
      isEnding: false,
      entryHook: '',
      description: '',
      handlers: [],
      fields: [],
      operations: [],
    },
  ],
  edges: [],
};
export const WORKFLOW_STATUS_FIELD = '_wuji_wf_status';
export const WORKFLOW_PARTICIPANT_FIELD = '_wuji_wf_participants';
export const WORKFLOW_HANDLER_FIELD = '_wuji_owner';
export const WORKFLOW_RESERVED_FIELDS = [WORKFLOW_HANDLER_FIELD, WORKFLOW_STATUS_FIELD, WORKFLOW_PARTICIPANT_FIELD];
export const WORKFLOW_TRANSITION_EVENT = 'workflow-transition';

export const WORKFLOW_SCHEMA = {
  additionalProperties: false,
  properties: {
    name: { type: 'string', minLength: 1, title: '名称' },
    statusField: { type: 'string', title: '状态字段' },
    notifyLink: { type: 'string', title: '通知详情链接' },
    notifySuccessMsg: { type: 'string', title: '通知成功消息' },
    nodes: {
      type: 'array',
      title: '状态列表',
      minItems: 1,
      items: {
        type: 'object',
        properties: {
          id: { type: 'string', minLength: 1, title: 'ID' },
          name: { type: 'string', minLength: 1, title: '名称' },
          isEnding: { type: 'boolean', title: '结束状态' },
          abnormal: { type: 'boolean', title: '异常结束状态' },
          entryHook: { type: 'string', title: 'Before Hook' },
          afterHook: { type: 'string', title: 'After Hook' },
          description: { type: 'string', title: '描述' },
          handlers: {
            type: 'array',
            title: '处理人',
            items: { type: 'string' },
            uniqueItems: true,
          },
          handlersField: {
            type: 'string',
            title: '处理人字段',
          },
          handlersMode: {
            type: 'string',
            title: '处理人模式',
          },
          operations: {
            type: 'array',
            title: '操作项',
            items: { type: 'string', minLength: 1 },
            uniqueItems: true,
          },
          fields: {
            type: 'array',
            title: '字段列表',
            items: { type: 'string', minLength: 1 },
            uniqueItems: true,
          },
        },
        required: ['id'],
      },
    },
    edges: {
      type: 'array',
      title: '状态转换',
      items: {
        type: 'object',
        properties: {
          from: { type: 'string', minLength: 1, title: '源状态' },
          to: { type: 'string', minLength: 1, title: '目标状态' },
          description: { type: 'string', title: '描述' },
          condition: {
            type: 'object',
            title: '转换条件',
            properties: {
              key: { type: 'string', minLength: 1, title: '处理结果字段', default: 'result' },
              operator: { type: 'string', enum: ['equal'], title: '匹配符' },
              value: { type: 'string', title: '期望操作' },
            },
            required: ['key', 'operator', 'value'],
          },
        },
        required: ['from', 'to', 'condition'],
      },
    },
  },
  required: ['name', 'nodes'],
};
