<template>
  <div class="container">
    <el-table
      :data="sources"
      :header-cell-style="background"
      border
    >
      <el-table-column
        prop="appName"
        label="项目"
      />
      <el-table-column
        prop="schemaName"
        label="表"
      />
      <el-table-column
        prop="operation"
        label="操作"
      >
        <template slot-scope="scope">
          <el-form
            v-model="scope.row"
          >
            <el-form-item
              label="启用电子流"
            >
              <el-switch
                v-model="scope.row.workflowSwitch"
                @change="workflowSwitchChange(scope.row)"
              />
              <el-button
                v-show="scope.row.workflowSwitch"
                type="text"
                icon="el-icon-edit"
                size="small"
                style="margin-left:10px"
                @click="showWorkflowDialog(scope.row)"
              >
                设置电子流
              </el-button>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
    </el-table>
    <workflow-dialog
      :visible.sync="visible"
      :app="currentApp"
      :schema="currentSchema"
    />
  </div>
</template>

<script>
import { fetchSchema, updateSchemaWorkflowSwitch } from '../../services';
import WorkflowDialog from './WorkflowDialog.vue';

export default {
  components: {
    WorkflowDialog,
  },
  props: {
    apps: {
      type: Array,
      default: () => [],
    },
    schemas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      currentApp: null,
      currentSchema: null,
      sources: [],
      background: {
        backgroundColor: '#fafafa',
      },
    };
  },
  computed: {
    appIdMap() {
      return this.apps.reduce((acc, app) => {
        // eslint-disable-next-line no-param-reassign
        acc[app.appId] = app;
        return acc;
      }, {});
    },
  },
  watch: {
    schemas() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.sources = [];
      this.reCreate += 1;
      const info = await this.fetchSchemasInfo();
      this.schemas.forEach((schema) => {
        const app = this.appIdMap[schema.appId];
        const { appId, schemaId } = schema;
        const { workflowSwitch = false, fields } = info[`${appId}:${schemaId}`];
        this.sources.push({
          appName: `${app.name}(${appId})`,
          schemaName: `${schema.name}(${schemaId})`,
          id: `${appId}.${schemaId}`,
          app,
          schema: { ...schema, fields },
          appId,
          schemaId,
          workflowSwitch,
          workflow: null,
        });
      });
    },
    showWorkflowDialog(data) {
      this.currentApp = data.app;
      this.currentSchema = data.schema;
      this.visible = true;
    },
    async workflowSwitchChange(data) {
      const { appId, schemaId, workflowSwitch } = data;
      try {
        await updateSchemaWorkflowSwitch(appId, schemaId, { uiInfo: { workflowSwitch } });
        this.$message.success('更新成功');
      } catch (err) {
        this.$message.error('更新失败', err);
      }
    },
    async fetchSchemasInfo() {
      const promises = this.schemas.map(schema => fetchSchema(schema.appId, schema.schemaId));
      const resps = await Promise.all(promises);
      const res = {};
      resps.forEach((resp) => {
        const { data } = resp;
        // eslint-disable-next-line no-underscore-dangle
        res[`${data._appId}:${data.id}`] = {
          workflowSwitch: Boolean(data.uiInfo?.workflowSwitch),
          fields: data.fields,
        };
      });
      return res;
    },
  },
};
</script>

<style scoped>
.container >>> .el-form-item {
  margin-bottom: 0;
}

</style>
