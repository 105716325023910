<template>
  <el-dialog
    title="设置电子流"
    :visible="visible"
    :append-to-body="true"
    width="1000px"
    custom-class="container"
    @close="close"
  >
    <div>
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="104px"
        size="small"
        @validate="validateEasyWorkflow"
      >
        <el-tabs v-model="activeTab">
          <el-tab-pane
            name="standard"
            label="标准"
          >
            <div class="content">
              <div>
                <div
                  class="form-container"
                  :style="{height: formHeight}"
                >
                  <div
                    v-for="(node, idx) in nodes"
                    :key="node.isEnding ? `is-the-end-${idx}` : idx"
                    :class="`form-item-wrapper ${node.isEnding ? '' : 'normal'}`"
                    :style="{display: node.__deleted ? 'none' : 'undefined'}"
                  >
                    <el-form-item
                      label="状态ID："
                      :prop="'nodes.' + idx + '.id'"
                      :rules="idRules"
                    >
                      <el-input
                        v-model="node.id"
                        type="text"
                        @change="nameChange"
                      />
                    </el-form-item>
                    <el-form-item
                      label="状态名称："
                      :prop="'nodes.' + idx + '.name'"
                    >
                      <el-input
                        v-model="node.name"
                        type="text"
                        @change="nameChange"
                      />
                    </el-form-item>
                    <el-form-item
                      label="审批页面："
                      :prop="'nodes.' + idx + '.pageId'"
                    >
                      <el-select
                        v-model="node.pageId"
                        :clearable="true"
                        style="width:100%"
                        @change="pageChange"
                      >
                        <el-option
                          v-for="page in pageList"
                          :key="page.id"
                          :label="`${page.name}(${page.path})`"
                          :value="page.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      v-if="!node.isEnding"
                      label="处理人："
                      :required="!node.isEnding"
                    >
                      <div style="display:flex">
                        <el-select
                          v-if="node.handlersMode === 'field'"
                          v-model="node.handlersField"
                          :clearable="true"
                          style="width:306px"
                          @change="handlersChange"
                        >
                          <el-option
                            v-for="field in fields"
                            :key="field.id"
                            :label="field.name"
                            :value="field.id"
                          />
                        </el-select>
                        <wuji-rtx
                          v-else
                          v-model="node.handlers"
                          @change="handlersChange"
                        />
                        <el-button
                          @click="handlerClick(node)"
                        >
                          {{ node.handlersMode === 'field' ? '选择用户' : '选择字段' }}
                        </el-button>
                      </div>
                    </el-form-item>
                    <el-form-item
                      v-if="!node.abnormal"
                      label="BeforeHook："
                    >
                      <el-input
                        v-model="node.entryHook"
                        placeholder="HTTP POST，支持寻址，如 http://hostname/api/service"
                        type="text"
                        @change="hookChange"
                      />
                    </el-form-item>
                    <el-form-item
                      label="AfterHook："
                    >
                      <el-input
                        v-model="node.afterHook"
                        placeholder="HTTP POST，支持寻址，如 http://hostname/api/service"
                        type="text"
                        @change="hookChange"
                      />
                    </el-form-item>
                    <span
                      v-if="!node.isEnding"
                      class="form-item-close"
                      @click="removeNode(idx)"
                    ><i class="el-icon-close" /></span>
                  </div>
                </div>
                <div
                  class="btn-wrapper"
                >
                  <el-button
                    style="width:100%"
                    size="small"
                    @click="newNode"
                  >
                    添加节点
                  </el-button>
                </div>
              </div>
              <div class="flowchart">
                <flowchart
                  :nodes="visibleNodes"
                  height="440px"
                />
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            name="advanced"
            label="高级设置"
          >
            <div :style="{height: formHeight, padding: '0 16px'}">
              <el-form-item
                label="审批状态字段："
                label-width="124px"
              >
                <el-select
                  v-model="form.statusField"
                  style="width:430px"
                  :clearable="true"
                  @change="statusFieldChange"
                >
                  <el-option
                    v-for="field in fields"
                    :key="field.id"
                    :label="field.name"
                    :value="field.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="通知详情链接："
                label-width="124px"
              >
                <el-input
                  v-model="form.notifyLink"
                  style="width:430px"
                  placeholder="支持插值，{id}，例如：http://wuji.host/xy/project-id/{id}"
                  @change="notifyLinkChange"
                />
              </el-form-item>
              <el-form-item
                label="通知成功消息："
                label-width="124px"
              >
                <el-input
                  v-model="form.notifySuccessMsg"
                  style="width:430px"
                  placeholder="支持插值，{name}，例如：您参与的 “{name}” 接入项目已经审批通过"
                  @change="notifySuccessMsgChange"
                />
              </el-form-item>
              <el-form-item
                v-if="!projectId"
                label="自动发起流程："
                label-width="124px"
              >
                <div style="display:flex;align-items:center">
                  <el-switch
                    v-model="autoStart"
                  />
                  <span style="margin-left:4px;color:#bababa">
                    <i class="el-icon-info" />
                    新建对象时在服务端自动发起流程
                  </span>
                </div>
              </el-form-item>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        style="visibility:hidden"
        @click="reset"
      >
        重 置
      </el-button>
      <el-button @click="close">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="invalid"
        @click="confirm"
      >
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import Ajv from 'ajv';
import Vue from 'vue';
import { get, cloneDeep } from 'lodash';
import {
  WORKFLOW_SCHEMA,
  EMPTY_WORKFLOW,
  ACCEPT_OPERATION,
  REJECT_OPERATION,
  NORMAL_ENDING_ID,
  NORMAL_ENDING_NAME,
  ABNORMAL_ENDING_ID,
  ABNORMAL_ENDING_NAME,
} from './constants';
import Flowchart from './Flowchart';
import { fetchWorkflow, updateWorkflow } from '../../services';

const ajv = new Ajv({ removeAdditional: true, unknownFormats: 'ignore' });
const schemaValidate = ajv.compile(WORKFLOW_SCHEMA);

export default {
  components: {
    Flowchart,
  },
  props: {
    visible: Boolean,
    app: {
      type: Object,
      default: null,
    },
    schema: {
      type: Object,
      default: null,
    },
    projectId: {
      type: String,
      default: '',
    },
    pageList: {
      type: Array,
      default: () => [],
    },
    flow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      workflow: {},
      invalid: true,
      workflowSchema: WORKFLOW_SCHEMA,
      error: '',
      activeTab: 'standard',
      form: { nodes: [], statusField: '', notifyLink: '', notifySuccessMsg: '' },
      formHeight: '400px',
      autoStart: !this.projectId,
      entityName: '',
      idRules: {
        required: true, validator: this.idValidator, trigger: 'blur',
      },
    };
  },
  computed: {
    appId() {
      return this.app?.appId;
    },
    schemaId() {
      return this.schema?.schemaId;
    },
    fields() {
      return this.schema?.fields ?? [];
    },
    nodes() {
      return this.form.nodes;
    },
    visibleNodes() {
      // eslint-disable-next-line no-underscore-dangle
      return this.nodes.filter(node => !node.__deleted);
    },
  },
  watch: {
    async visible() {
      if (this.visible) {
        await this.initWorkflow();
      }
    },
  },
  mounted() {
    if (this.visible) this.initWorkflow();
    // in xy-client
    if (!Vue.component('WujiRtx')) {
      // box-sizing of xy-client is `border-box`
      this.formHeight = '416px';
    }
  },
  methods: {
    async initWorkflow() {
      let workflow = null;
      if (this.projectId) {
        workflow = this.flow;
      } else {
        const resp = await fetchWorkflow(this.appId, this.schemaId);
        workflow = resp.data;
      }

      if (workflow) {
        this.workflow = workflow;
        this.form.nodes = workflow.nodes;
        this.form.statusField = workflow.statusField || '';
        this.form.notifyLink = workflow.notifyLink || '';
        this.form.notifySuccessMsg = workflow.notifySuccessMsg || '';
        this.invalid = false;
        this.autoStart = !workflow.manualStart;
        this.entityName = workflow.entityName || '';
      } else {
        this.reset();
        this.workflow.nodes = [...this.form.nodes];
      }
    },
    handlerClick(node) {
      const { handlersMode } = node;
      this.$set(node, 'handlersMode', handlersMode === 'field' ? 'rtx' : 'field');
    },
    newNode() {
      const node = {
        id: '',
        name: '',
        entryHook: '',
        handlers: [],
        handlersField: '',
        handlersMode: 'rtx',
      };
      const index = this.nodes.findIndex(node => node.isEnding);
      this.nodes.splice(index, 0, node);
      this.invalid = true;
      // make the new node visible
      this.$nextTick(() => {
        const items = this.$refs.form.$el.querySelectorAll('.form-item-wrapper.normal');
        items[items.length - 1].scrollIntoView({ block: 'end', behavior: 'smooth' });
      }, 0);
    },
    removeNode(index) {
      if (this.visibleNodes.filter(node => !node.isEnding).length === 1) {
        this.$message.info('至少需要一个非结束状态节点');
        return;
      }
      // 为了保持 div.form-item-wrapper 的 key 不变
      // 采用标记删除方式
      // eslint-disable-next-line no-underscore-dangle
      this.nodes[index].__deleted = true;
      this.$refs.form.validate();
      this.form.nodes = [...this.nodes];
    },
    valueChange() {
      const errors = this.validate();
      if (errors.length) {
        this.error = errors.join('');
        this.invalid = true;
      } else {
        this.invalid = false;
        this.error = '';
      }
    },

    validate() {
      const valid = schemaValidate(this.workflow);
      if (!valid) {
        return schemaValidate.errors.map(error => `'${error.dataPath ? error.dataPath : '.'}' ${error.message}`);
      }

      const errors = [];
      const nodeIds = [];
      const endings = [];

      const nodes = this.workflow.nodes || [];
      nodes.forEach((node) => {
        if (nodeIds.includes(node.id)) {
          errors.push(`重复节点：'${node.id}'`);
        } else {
          nodeIds.push(node.id);
        }
        if (node.isEnding) {
          endings.push(node);
        } else if (!node.operations || node.operations.length === 0) {
          errors.push(`非结束状态节点应至少存在一个操作项: ${node.id}`);
        }
      });

      if (endings.length === 0) {
        errors.push('请确保至少存在一个结束状态节点');
      }

      const edges = this.workflow.edges || [];
      edges.forEach((edge) => {
        if (!nodeIds.includes(edge.from)) {
          errors.push(`边数据中不存在源：'${edge.from}'`);
        }
        if (!nodeIds.includes(edge.to)) {
          errors.push(`边数据中不存在目标：'${edge.to}'`);
        }
      });

      return errors;
    },

    close() {
      this.form.nodes = [];
      this.$emit('update:visible', false);
    },
    nameChange() {
      // notify flowchart to update
      this.form.nodes = [...this.nodes];
      this.$refs.form.validate();
    },
    handlersChange() {
      this.validateEasyWorkflow();
    },

    pageChange() {
      this.validateEasyWorkflow();
    },
    hookChange() {
      this.validateEasyWorkflow();
    },

    validateEasyWorkflow() {
      if (this.visibleNodes.length === 0) return;
      const ids = [];
      let ok = true;
      this.visibleNodes.some((node) => {
        if (ids.includes(node.id)) {
          ok = false;
          // break
          return true;
        }
        ids.push(node.id);
        return false;
      });

      // 结束状态不需要处理人，其他状态需要
      ok = ok && this.visibleNodes
        .filter(node => !node.isEnding)
        .every(node => (!!node.handlers.length || node.handlersField));
      this.invalid = !ok;
      if (ok) {
        this.generateEasyWorkflow();
      }
    },

    generateEasyWorkflow() {
      if (this.visibleNodes.length === 0) return;

      const nodes = [];
      const edges = [];
      const abnormalNode = this.visibleNodes.find(n => n.isEnding && n.abnormal);
      const abnormalId = abnormalNode ? abnormalNode.id : ABNORMAL_ENDING_ID;

      this.visibleNodes.forEach((visibleNode, i) => {
        const node = cloneDeep(EMPTY_WORKFLOW.nodes[0]);
        node.id = visibleNode.id;
        node.name = visibleNode.name || visibleNode.id;
        node.pageId = visibleNode.pageId || '';
        node.handlers = visibleNode.handlers;
        node.handlersField = visibleNode.handlersField;
        node.handlersMode = visibleNode.handlersMode;
        node.entryHook = visibleNode.entryHook || '';
        node.afterHook = visibleNode.afterHook || '';
        node.isEnding = !!visibleNode.isEnding;
        node.operations = node.isEnding ? [] : [ACCEPT_OPERATION, REJECT_OPERATION];
        node.abnormal = visibleNode.abnormal;
        nodes.push(node);

        // edge: from normal to abnormal-ending node
        if (!node.isEnding) {
          edges.push({
            from: visibleNode.id,
            to: abnormalId,
            condition: {
              key: 'result',
              operator: 'equal',
              value: REJECT_OPERATION,
            },
          });
        }

        // edge: from normal to next node, but not to the abnormal one
        if (i > 0 && !node.abnormal) {
          edges.push({
            from: this.visibleNodes[i - 1].id,
            to: visibleNode.id,
            condition: {
              key: 'result',
              operator: 'equal',
              value: ACCEPT_OPERATION,
            },
          });
        }
      });

      this.workflow = {
        nodes,
        edges,
        name: `${this.appId}-${this.schemaId}-标准电子流`,
        statusField: this.form.statusField,
        notifyLink: this.form.notifyLink,
        notifySuccessMsg: this.form.notifySuccessMsg,
      };
    },

    statusFieldChange() {
      this.workflow.statusField = this.form.statusField;
    },
    notifyLinkChange() {
      this.workflow.notifyLink = this.form.notifyLink;
    },
    notifySuccessMsgChange() {
      this.workflow.notifySuccessMsg = this.form.notifySuccessMsg;
    },

    idValidator(rule, value, callback) {
      const { field } = rule;
      const id = get(this.form, field);
      if (!id) {
        callback(new Error('必填'));
      } else if (!id.match(/^[\w.\\-]+$/)) {
        callback(new Error('有效字符 [a-zA-Z0-9_.\\-]+'));
      } else {
        let count = 0;
        this.nodes.forEach((node) => {
          // eslint-disable-next-line no-underscore-dangle
          if (!node.__deleted && node.id === id) count += 1;
        });
        if (count >= 2) {
          callback(new Error('状态ID须保持唯一'));
        } else {
          callback();
        }
      }
    },

    reset() {
      const nodes = [
        {
          id: '',
          name: '',
          pageId: '',
          entryHook: '',
          handlers: [],
          handlersField: '',
          handlersMode: 'rtx',
        },
        {
          id: NORMAL_ENDING_ID,
          name: NORMAL_ENDING_NAME,
          pageId: '',
          entryHook: '',
          handlers: [],
          handlersField: '',
          handlersMode: 'rtx',
          isEnding: true,
        },
        {
          id: ABNORMAL_ENDING_ID,
          name: ABNORMAL_ENDING_NAME,
          pageId: '',
          entryHook: '',
          handlers: [],
          handlersField: '',
          handlersMode: 'rtx',
          isEnding: true,
          abnormal: true,
        },
      ];
      this.form.nodes = cloneDeep(nodes);
    },

    async confirm() {
      const errors = this.validate();
      if (errors.length) {
        this.error = errors.join('');
        return;
      }

      if (this.projectId) {
        this.workflow.schema = '';
        this.$emit('change', this.workflow);
      } else {
        await updateWorkflow({
          ...this.workflow,
          appId: this.appId,
          schemaId: this.schemaId,
          fieldId: this.fieldId,
          manualStart: !this.autoStart,
          schema: '',
          entityName: this.entityName,
        });
      }

      this.close();
    },
  },
};
</script>
<style lang="scss">
.ant-select-dropdown {
  // element-ui 的 modal z-index > 2000, 为保证 wuji-rtx 组件的下拉框不被遮挡
  z-index: 3999;
}

.container {
  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-input__inner {
    border-radius: 4px;
    padding-left: 8px;
  }

  .el-select__tags {
    border-radius: 4px;
    // 为了覆盖内联样式，使得 wuji-rtx 对齐其他 input 框
    width: unset !important;
  }

  .wuji-component {
    padding-bottom: 0;
  }

  .el-select .el-input .el-select__caret {
    color: #aaa;
  }
}
</style>

<style lang="scss" scoped>
.content {
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.form-container {
  padding: 0 16px 16px;
  height: 400px;
  overflow-y: auto;
}

.btn-wrapper {
  padding: 0 16px;
}

.form-item-wrapper {
  position: relative;
  background-color: #efefef;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 24px 32px 2px 16px;
}

.form-item-close {
  position: absolute;
  top: 4px;
  right: 8px;
  cursor: pointer;
}
</style>
