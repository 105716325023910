// declaration is the same as WUJI.request
interface RequestParam {
  path: string;
  data?: any;
  query?: any;
  method?: string;
}

const request = async ({
  path,
  query,
  data,
  method = 'GET',
}: RequestParam): Promise<any> => new Promise(async (resolve, reject) => {
  try {
    const resp = await fetch(path, {
      method,
      body: JSON.stringify(data),
    });

    if (resp.ok) {
      resolve(resp.json());
    } else {
      reject(resp);
    }
  } catch (error) {
    reject(error);
  }
});

const registration = {
  request,
};

export const setHTTPClient = (request: (options: any) => Promise<any>) => registration.request = request;
export const getHTTPClient = () => registration.request;
